import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { Input, Form, Select, Switch } from 'formik-antd';
import { Spin, message, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import * as Yup from 'yup';
import history from '~/services/history';

import { updateProfile } from '~/store/modules/user/actions';
import DefautLayout from '~/pages/_layouts/full';

import PageTitle from '~/components/PageTitle';
import FormControl from '~/components/Form/FormControl';
import Button from '~/components/Button';
import Box from '~/components/Box';
import Row from '~/components/Row';
import ImageUpload from '~/components/ImageUpload';
import InputCurrency from '~/components/Form/InputCurrency';

import errorHandler from '~/Utils/errorHandler';
import api from '~/services/api';

export default function Profile(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { Title } = Typography;
  const [loading, setLoading] = useState(false);
  const { profile } = useSelector(state => state.user);
  const [roles, setRoles] = useState([]);
  const [recordData, setRecordData] = useState([]);
  const [userApprovals, setUserApprovals] = useState([]);
  const [approvalIds, setApprovalIds] = useState([]);
  const roleList = useSelector(state => state && state.user && state.user.profile && state.user.profile.roles);

  const { Option } = Select;

  const bancos = [
    { code: '001', name: 'Banco do Brasil' },
    { code: '003', name: 'Banco da Amazônia' },
    { code: '004', name: 'Banco do Nordeste' },
    { code: '021', name: 'Banestes' },
    { code: '025', name: 'Banco Alfa' },
    { code: '027', name: 'Besc' },
    { code: '029', name: 'Banerj' },
    { code: '031', name: 'Banco Beg' },
    { code: '033', name: 'Banco Santander Banespa' },
    { code: '036', name: 'Banco Bem' },
    { code: '037', name: 'Banpará' },
    { code: '038', name: 'Banestado' },
    { code: '039', name: 'BEP' },
    { code: '040', name: 'Banco Cargill' },
    { code: '041', name: 'Banrisul' },
    { code: '044', name: 'BVA' },
    { code: '045', name: 'Banco Opportunity' },
    { code: '047', name: 'Banese' },
    { code: '062', name: 'Hipercard' },
    { code: '063', name: 'Ibibank' },
    { code: '065', name: 'Lemon Bank' },
    { code: '066', name: 'Banco Morgan Stanley Dean Witter' },
    { code: '069', name: 'BPN Brasil' },
    { code: '070', name: 'Banco de Brasília – BRB' },
    { code: '072', name: 'Banco Rural' },
    { code: '073', name: 'Banco Popular' },
    { code: '074', name: 'Banco J. Safra' },
    { code: '075', name: 'Banco CR2' },
    { code: '076', name: 'Banco KDB' },
    { code: '077', name: 'INTER' },
    { code: '085', name: 'Ailos' },
    { code: '096', name: 'Banco BMF' },
    { code: '104', name: 'Caixa Econômica Federal' },
    { code: '107', name: 'Banco BBM' },
    { code: '116', name: 'Banco Único' },
    { code: '151', name: 'Nossa Caixa' },
    { code: '175', name: 'Banco Finasa' },
    { code: '184', name: 'Banco Itaú BBA' },
    { code: '204', name: 'American Express Bank' },
    { code: '208', name: 'Banco Pactual' },
    { code: '212', name: 'Banco Matone' },
    { code: '213', name: 'Banco Arbi' },
    { code: '214', name: 'Banco Dibens' },
    { code: '217', name: 'Banco Joh Deere' },
    { code: '218', name: 'Banco Bonsucesso' },
    { code: '222', name: 'Banco Calyon Brasil' },
    { code: '224', name: 'Banco Fibra' },
    { code: '225', name: 'Banco Brascan' },
    { code: '229', name: 'Banco Cruzeiro' },
    { code: '230', name: 'Unicard' },
    { code: '233', name: 'Banco GE Capital' },
    { code: '237', name: 'Bradesco' },
    { code: '241', name: 'Banco Clássico' },
    { code: '243', name: 'Banco Stock Máxima' },
    { code: '246', name: 'Banco ABC Brasil' },
    { code: '248', name: 'Banco Boavista Interatlântico' },
    { code: '249', name: 'Investcred Unibanco' },
    { code: '250', name: 'Banco Schahin' },
    { code: '252', name: 'Fininvest' },
    { code: '254', name: 'Paraná Banco' },
    { code: '260', name: 'Nu Pagamentos S.A.' },
    { code: '263', name: 'Banco Cacique' },
    { code: '265', name: 'Banco Fator' },
    { code: '266', name: 'Banco Cédula' },
    { code: '300', name: 'Banco de la Nación Argentina' },
    { code: '318', name: 'Banco BMG' },
    { code: '320', name: 'Banco Industrial e Comercial' },
    { code: '356', name: 'ABN Amro Real' },
    { code: '341', name: 'Itau' },
    { code: '347', name: 'Sudameris' },
    { code: '351', name: 'Banco Santander' },
    { code: '353', name: 'Banco Santander Brasil' },
    { code: '366', name: 'Banco Societe Generale Brasil' },
    { code: '370', name: 'Banco WestLB' },
    { code: '376', name: 'JP Morgan' },
    { code: '380', name: 'PICPAY' },
    { code: '389', name: 'Banco Mercantil do Brasil' },
    { code: '394', name: 'Banco Mercantil de Crédito' },
    { code: '399', name: 'HSBC' },
    { code: '409', name: 'Unibanco' },
    { code: '412', name: 'Banco Capital' },
    { code: '422', name: 'Banco Safra' },
    { code: '453', name: 'Banco Rural' },
    { code: '456', name: 'Banco Tokyo Mitsubishi UFJ' },
    { code: '464', name: 'Banco Sumitomo Mitsui Brasileiro' },
    { code: '477', name: 'Citibank' },
    { code: '479', name: 'Itaubank (antigo Bank Boston)' },
    { code: '487', name: 'Deutsche Bank' },
    { code: '488', name: 'Banco Morgan Guaranty' },
    { code: '492', name: 'Banco NMB Postbank' },
    { code: '494', name: 'Banco la República Oriental del Uruguay' },
    { code: '495', name: 'Banco La Provincia de Buenos Aires' },
    { code: '505', name: 'Banco Credit Suisse' },
    { code: '600', name: 'Banco Luso Brasileiro' },
    { code: '604', name: 'Banco Industrial' },
    { code: '610', name: 'Banco VR' },
    { code: '611', name: 'Banco Paulista' },
    { code: '612', name: 'Banco Guanabara' },
    { code: '613', name: 'Banco Pecunia' },
    { code: '623', name: 'Banco Panamericano' },
    { code: '626', name: 'Banco Ficsa' },
    { code: '630', name: 'Banco Intercap' },
    { code: '633', name: 'Banco Rendimento' },
    { code: '634', name: 'Banco Triângulo' },
    { code: '637', name: 'Banco Sofisa' },
    { code: '638', name: 'Banco Prosper' },
    { code: '643', name: 'Banco Pine' },
    { code: '652', name: 'Itaú Holding Financeira' },
    { code: '653', name: 'Banco Indusval' },
    { code: '654', name: 'Banco A.J. Renner' },
    { code: '655', name: 'Banco Votorantim' },
    { code: '707', name: 'Banco Daycoval' },
    { code: '719', name: 'Banif' },
    { code: '721', name: 'Banco Credibel' },
    { code: '734', name: 'Banco Gerdau' },
    { code: '735', name: 'Banco Pottencial' },
    { code: '738', name: 'Banco Morada' },
    { code: '739', name: 'Banco Galvão de Negócios' },
    { code: '740', name: 'Banco Barclays' },
    { code: '741', name: 'BRP' },
    { code: '743', name: 'Banco Semear' },
    { code: '745', name: 'Banco Citibank' },
    { code: '746', name: 'Banco Modal' },
    { code: '747', name: 'Banco Rabobank International' },
    { code: '748', name: 'Banco Cooperativo Sicredi' },
    { code: '749', name: 'Banco Simples' },
    { code: '751', name: 'Dresdner Bank' },
    { code: '752', name: 'BNP Paribas' },
    { code: '753', name: 'Banco Comercial Uruguai' },
    { code: '755', name: 'Banco Merrill Lynch' },
    { code: '756', name: 'Banco Cooperativo do Brasil' },
    { code: '757', name: 'KEB' },
  ];

  const handleSave = async (values, { setErrors }) => {
    setLoading(true);
    try {
      let banco = bancos.filter(item => `${item.code} - ${item.name}` === values.bankInformationBank);

      if (values.bankInformation !== null) {
        // Se nao tiver nenhum dos dados bancários, seta como nulo para remover.
        if (banco && banco[0] && values.bankInformationAgencyNumber && values.bankInformationAccount) {
          values.bankInformation = {
            id: values.bankInformationId ? values.bankInformationId : null,
            bank: banco[0].code ? banco[0].code : null,
            agencyNumber: values.bankInformationAgencyNumber ? values.bankInformationAgencyNumber : null,
            account: values.bankInformationAccount ? values.bankInformationAccount : null,
            deleted: false,
          };
        } else {
          values.bankInformation = null;
        }
      }

      delete values.bankInformationId;
      delete values.bankInformationBank;
      delete values.bankInformationAgencyNumber;
      delete values.bankInformationAccount;
      values.roles = roles.filter(item => item.id.toString() === values.role_id);
      if (values.newPassword) {
        values.password = values.newPassword;
        delete values.newPassword;
        delete values.password_confirmation;
      }
      values.status === true ? (values.status = 'ACTIVE') : (values.status = 'INACTIVE');
      // eslint-disable-next-line
      {
        values.document === '' && (values.document = null);
      }
      delete values.isAdmin;
      delete values.role_id;
      values.approvalIds = approvalIds;

      await api.put('/users/update', values);
      if (profile.roles[0].name === 'ROLE_ADMIN') {
        history.push('/usuarios');
      } else {
        history.push('/lancamentos');
      }
      message.success(t('messages:userSuccessEdit'));
      fetchRecords();
    } catch (error) {
      setErrors(errorHandler(error));
    }
    setLoading(false);
  };

  const handleCancel = () => {
    if (profile.roles[0].name === 'ROLE_ADMIN') {
      history.push('/usuarios');
    } else {
      history.push('/lancamentos');
    }
  };

  const handleUpload = async () => {
    setLoading(true);
    try {
      const { data } = await api.get('/users/find');
      dispatch(updateProfile(data));
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const fetchRoles = async () => {
    setLoading(true);
    try {
      const { data } = await api.get('/roles/findAll');
      setRoles(data);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleChangeUserApprovals = (value) => {
    setApprovalIds(value);
  };

  const handleChangeRole = (value) => {
    setApprovalIds([]);
    setUserApprovals([]);
    fetchUserApprovals(value);
  };

  const fetchRecords = async () => {
    setLoading(true);
    try {
      const { id } = props.match.params;

      if (id === profile.id.toString()) {
        const { data } = await api.get('/users/find');
        // eslint-disable-next-line
        {
          !!data.document === false && (data.document = '');
        }
        // eslint-disable-next-line
        {
          data.status === 'ACTIVE' ? (data.status = true) : (data.status = false);
        }
        if (data.bankInformation) {
          let bank = bancos.filter(item => item.code === data.bankInformation.bank);
          data.bankInformationId = data.bankInformation.id;
          data.bankInformationBank = `${bank[0].code} - ${bank[0].name}`;
          data.bankInformationAgencyNumber = data.bankInformation.agencyNumber;
          data.bankInformationAccount = data.bankInformation.account;
        } else {
          data.bankInformation = {};
        }
        data.isAdmin = roleList && roleList[0] && roleList[0].name === 'ROLE_ADMIN';
        data.role_id = data.roles[0].id.toString();
        setApprovalIds(data.approvalIds);
        fetchUserApprovals(data.roles[0].id);
        setRecordData(data);
      } else {
        const { data } = await api.get('/users/findById', {
          params: { id },
          headers: { 'Content-Type': 'multipart/form-data' },
        });
        // eslint-disable-next-line
        {
          !!data.document === false && (data.document = '');
        }
        // eslint-disable-next-line
        {
          data.status === 'ACTIVE' ? (data.status = true) : (data.status = false);
        }

        if (data.bankInformation) {
          let bank = bancos.filter(item => item.code === data.bankInformation.bank);
          data.bankInformationId = data.bankInformation.id;
          data.bankInformationBank = `${bank[0].code} - ${bank[0].name}`;
          data.bankInformationAgencyNumber = data.bankInformation.agencyNumber;
          data.bankInformationAccount = data.bankInformation.account;
        } else {
          data.bankInformation = {};
        }

        data.isAdmin = roleList && roleList[0] && roleList[0].name === 'ROLE_ADMIN';
        data.role_id = data.roles[0].id.toString();
        setApprovalIds(data.approvalIds);
        fetchUserApprovals(data.roles[0].id);
        setRecordData(data);
      }
      fetchRoles();
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const fetchUserApprovals = async (roleId) => {
    setLoading(true);
    try {
      const { id } = props.match.params;
      const { data } = await api.get('/users/findAllApprovals', {
        params: { roleId: roleId, approverId: id },
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      setUserApprovals(data);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchRecords();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const schema = Yup.object().shape({
    isAdmin: Yup.boolean().nullable(),
    name: Yup.string().required(),
    email: Yup.string()
      .email()
      .required(),
    dailyValueBreakfast: Yup.number().required(),
    dailyValueLunch: Yup.number().required(),
    dailyValueDinner: Yup.number().required(),
    valuePaidMileage: Yup.number().required(),
    document: Yup.string().when('isAdmin', {
      is: value => value !== true,
      then: Yup.string()
        .typeError('campo obrigatório')
        .required(),
    }),
    newPassword: Yup.string().nullable(),
    password_confirmation: Yup.string().oneOf([Yup.ref('newPassword'), null], 'Senhas diferentes'),
    bankInformationBank: Yup.string().when('isAdmin', {
      is: value => value !== true,
      then: Yup.string().required(),
    }),
    bankInformationAgencyNumber: Yup.string().when('isAdmin', {
      is: value => value !== true,
      then: Yup.string().required(),
    }),
    bankInformationAccount: Yup.string().when('isAdmin', {
      is: value => value !== true,
      then: Yup.string().required(),
    }),
  });

  return (
    <DefautLayout>
      <Box>
        <PageTitle title={'Perfil'} />
        <Formik initialValues={recordData} enableReinitialize onSubmit={handleSave} validationSchema={schema}>
          {({ errors, isSubmitting, submitForm, resetForm, touched, values, setFieldValue }) => (
            <Spin spinning={loading || isSubmitting}>
              <Form>
                <Row>
                  <FormControl cols={{ xs: 24, sm: 24, md: 6, lg: 6, xl: 6 }} required field="avatar">
                    <ImageUpload
                      image={profile && profile.image}
                      upUrl={`${process.env.REACT_APP_BACKEND_URL}/uploadImage`}
                      postUpload={handleUpload}
                      postDelete={handleUpload}
                      message={t('screens:profile.data.sendAvatar')}
                    />
                  </FormControl>
                  <FormControl cols={{ xs: 24, sm: 24, md: 16, lg: 16, xl: 16 }}>
                    <Title level={4} style={{ color: '#0f4c81' }}>
                      {t('screens:profile.data.personalInfo')}
                    </Title>
                    <Row>
                      <FormControl
                        cols={{ xs: 24, sm: 24, md: 8, lg: 8, xl: 8 }}
                        label={t('screens:profile.data.name')}
                        required
                        error={(touched.name && errors.name) || errors.name}
                        field="name"
                      >
                        <Input name="name" />
                      </FormControl>
                      <FormControl
                        cols={{ xs: 24, sm: 24, md: 8, lg: 8, xl: 8 }}
                        error={(touched.email && errors.email) || errors.email}
                        field="email"
                        label={t('screens:users.data.email')}
                        required
                      >
                        <Input type="email" name="email" />
                      </FormControl>
                      <FormControl
                        error={(touched.role_id && errors.role_id) || errors.role_id}
                        cols={{ xs: 24, sm: 24, md: 6, lg: 6, xl: 6 }}
                        field="role_id"
                        label={t('screens:users.data.role')}
                        required
                      >
                        <Select
                          disabled={profile && profile.roles && profile.roles[0] && profile.roles[0].name !== 'ROLE_ADMIN'}
                          onChange={
                            roleId => {
                              handleChangeRole(roleId);
                              setFieldValue('approvalIds', []);
                            }
                          }
                          showSearch
                          name="role_id"
                          label={t('screens:users.data.role')}
                          required
                        >
                          {roles.map(item => {
                            return (
                              <Option key={item.id} value={item.id.toString()}>
                                {item.description}
                              </Option>
                            );
                          })}
                        </Select>
                      </FormControl>
                      <FormControl
                        cols={{ xs: 24, sm: 24, md: 2, lg: 2, xl: 2 }}
                        field="active"
                        label={t('screens:users.data.active')}
                      >
                        <Switch
                          disabled={
                            profile && profile.roles && profile.roles[0] && profile.roles[0].name !== 'ROLE_ADMIN'
                          }
                          name="status"
                          checked={recordData.status === undefined ? true : recordData.status}
                          checkedChildren={t('messages:yes')}
                          unCheckedChildren={t('messages:no')}
                          onChange={e => {
                            recordData.status = e;
                          }}
                        ></Switch>
                      </FormControl>
                    </Row>
                    <Row>
                      {/* <FormControl
                        cols={{ xs: 24, sm: 24, md: 8, lg: 8, xl: 8 }}
                        label={t('screens:profile.data.oldPassword')}
                        required
                        error={errors.password}
                        field="password"
                      >
                        <Input type="password" name="password" />
                      </FormControl> */}
                      <FormControl
                        cols={{ xs: 24, sm: 24, md: 8, lg: 8, xl: 8 }}
                        label={t('screens:profile.data.newPassword')}
                        required
                        error={(touched.newPassword && errors.newPassword) || errors.newPassword}
                        field="newPassword"
                      >
                        <Input name="newPassword" type="password" />
                      </FormControl>
                      <FormControl
                        cols={{ xs: 24, sm: 24, md: 8, lg: 8, xl: 8 }}
                        label={t('screens:profile.data.newPasswordConfirmation')}
                        required
                        error={
                          (touched.password_confirmation && errors.password_confirmation) ||
                          errors.password_confirmation
                        }
                        field="password_confirmation"
                      >
                        <Input name="password_confirmation" type="password" />
                      </FormControl>
                      <FormControl
                        cols={{ xs: 24, sm: 24, md: 6, lg: 6, xl: 6 }}
                        style={{ display: 'none' }}
                        field="isAdmin"
                      >
                        <Input name={'isAdmin'} style={{ display: 'none' }} />
                      </FormControl>
                      
                      {roleList[0].name === 'ROLE_ADMIN' && values.role_id !== '1' 
                        && values.role_id !== '4' ? 
                        <FormControl
                            cols={{ xs: 24, sm: 24, md: 16, lg: 16, xl: 16 }}
                            label={t('screens:users.data.userApprovals')}
                            field="approvalIds"
                          >
                            <Select 
                              mode="multiple"
                              showSearch
                              name="approvalIds" 
                              onChange={handleChangeUserApprovals}
                              disabled={values.role_id === ''} 
                            >
                              {userApprovals.map(user => {
                                return (
                                  <Option key={user.id} value={user.id}>
                                    {user.name}{user.status.toString() === 'INACTIVE' ? ' (' + t('screens:status.inactive') + ')' : ''}
                                  </Option>
                                );
                              })}
                            </Select>
                        </FormControl> : null
                      }
                    </Row>
                    <Title level={4} style={{ color: '#0f4c81' }}>
                      {t('screens:profile.data.bankInfo')}
                    </Title>
                    <Row>
                      <FormControl
                        cols={{ xs: 24, sm: 24, md: 8, lg: 8, xl: 8 }}
                        label={t('screens:profile.data.bank')}
                        required
                        error={
                          (touched.bankInformationBank && errors.bankInformationBank) || errors.bankInformationBank
                        }
                        field="bankInformationBank"
                      >
                        <Select showSearch name="bankInformationBank" label={t('screens:users.data.role')} required>
                          {bancos.map(item => {
                            return (
                              <Option key={item.code} value={`${item.code} - ${item.name}`}>
                                {`${item.code} - ${item.name}`}
                              </Option>
                            );
                          })}
                        </Select>
                      </FormControl>
                      <FormControl
                        cols={{ xs: 24, sm: 24, md: 8, lg: 8, xl: 8 }}
                        label={t('screens:profile.data.agency')}
                        required
                        error={
                          (touched.bankInformationAgencyNumber && errors.bankInformationAgencyNumber) ||
                          errors.bankInformationAgencyNumber
                        }
                        field="bankInformationAgencyNumber"
                      >
                        <Input name="bankInformationAgencyNumber" />
                      </FormControl>
                    </Row>
                    <Row>
                      <FormControl
                        cols={{ xs: 24, sm: 24, md: 8, lg: 8, xl: 8 }}
                        label={t('screens:profile.data.account')}
                        required
                        error={
                          (touched.bankInformationAccount && errors.bankInformationAccount) ||
                          errors.bankInformationAccount
                        }
                        field="bankInformationAccount"
                      >
                        <Input name="bankInformationAccount" />
                      </FormControl>
                      <FormControl
                        cols={{ xs: 24, sm: 24, md: 8, lg: 8, xl: 8 }}
                        label={t('screens:profile.data.cpf')}
                        required
                        error={(touched.document && errors.document) || errors.document}
                        field="document"
                      >
                        <Input name="document" />
                      </FormControl>
                    </Row>
                    <Title level={4} style={{ color: '#0f4c81' }}>
                      {t('screens:profile.data.daily')}
                    </Title>
                    <Row>
                      <FormControl
                        cols={{ xs: 24, sm: 24, md: 4, lg: 4, xl: 4 }}
                        label={t('screens:profile.data.breakfast')}
                        required
                        error={
                          (touched.dailyValueBreakfast && errors.dailyValueBreakfast) || errors.dailyValueBreakfast
                        }
                        field="dailyValueBreakfast"
                      >
                        <InputCurrency
                          name="dailyValueBreakfast"
                          precision={2}
                          decimalSeparator=","
                          currency="R$"
                          disabled={
                            profile && profile.roles && profile.roles[0] && profile.roles[0].name !== 'ROLE_ADMIN'
                          }
                        />
                      </FormControl>
                      <FormControl
                        cols={{ xs: 12, sm: 24, md: 4, lg: 4, xl: 4 }}
                        label={t('screens:profile.data.lunch')}
                        required
                        error={(touched.dailyValueLunch && errors.dailyValueLunch) || errors.dailyValueLunch}
                        field="dailyValueLunch"
                      >
                        <InputCurrency
                          name="dailyValueLunch"
                          precision={2}
                          decimalSeparator=","
                          currency="R$"
                          disabled={
                            profile && profile.roles && profile.roles[0] && profile.roles[0].name !== 'ROLE_ADMIN'
                          }
                        />
                      </FormControl>
                      <FormControl
                        cols={{ xs: 12, sm: 24, md: 4, lg: 4, xl: 4 }}
                        label={t('screens:profile.data.dinner')}
                        required
                        error={(touched.dailyValueDinner && errors.dailyValueDinner) || errors.dailyValueDinner}
                        field="dailyValueDinner"
                      >
                        <InputCurrency
                          name="dailyValueDinner"
                          precision={2}
                          decimalSeparator=","
                          currency="R$"
                          disabled={
                            profile && profile.roles && profile.roles[0] && profile.roles[0].name !== 'ROLE_ADMIN'
                          }
                        />
                      </FormControl>
                      <FormControl
                        cols={{ xs: 12, sm: 24, md: 4, lg: 4, xl: 4 }}
                        label={t('screens:profile.data.km')}
                        required
                        error={(touched.valuePaidMileage && errors.valuePaidMileage) || errors.valuePaidMileage}
                        field="valuePaidMileage"
                      >
                        <Input
                          disabled={
                            profile && profile.roles && profile.roles[0] && profile.roles[0].name !== 'ROLE_ADMIN'
                          }
                          name="valuePaidMileage"
                        />
                      </FormControl>
                    </Row>
                    <FormControl cols={{ xs: 24, sm: 24, md: 24, lg: 24, xl: 24 }}>
                      <Row>
                        <Button type="submit" color="primary" style={{ marginLeft: 'auto' }}>
                          Salvar
                        </Button>
                        <Button style={{ marginLeft: '10px' }} onClick={handleCancel}>
                          Cancelar
                        </Button>
                      </Row>
                    </FormControl>
                  </FormControl>
                </Row>
              </Form>
            </Spin>
          )}
        </Formik>
      </Box>
    </DefautLayout>
  );
}
